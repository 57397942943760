<template>
  <b-card>
    <h1>Impressum</h1>

    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>KOMBO medical solutions UG<br />
    Brunnenkamp, 1<br />
    D-38642 Goslar</p>

    <p>Handelsregister: 27371<br />
    Registergericht: Stendal</p>

    <p><strong>Vertreten durch:</strong><br />
    Kubiack, Kim<br /></p>

    <h2>Kontakt</h2>
    <p>  <!--Telefon: &#91;Telefonnummer&#93;<br />    -->
    E-Mail: info@dentaltwin.de</p>

       <!--
    <h2>Umsatzsteuer-ID</h2>
    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
    TODO_USTD</p>
    -->

    <h2>Redaktionell verantwortlich</h2>
    <p>Rupprecht, Marcel</p>

    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>  
      
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
